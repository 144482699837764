<template>
  <div class="index">

    <!--  标题  -->
    <div style="margin-bottom: 30px;">

      <div style="overflow:hidden;padding: 12px 12px 0;margin-bottom: -12px;">
        <van-cell @click="$refs.group.$show()" v-model="group.name" required
                  style="padding: 16px;font-size: 14px !important;" title="选择分组">
          <template #right-icon>
            <van-icon style="margin-left: 10px;font-size: 16px;line-height: inherit" @click.stop="clearGroup"
                      v-if="group.name != '全部分组'" name="clear"/>
          </template>
        </van-cell>
        <van-cell required style="padding: 16px;font-size: 14px !important;margin-top: 12px" title="输入标题">
          <van-field
              v-model="title"
              placeholder="请输入标题"
              input-align="right"
          />
        </van-cell>
      </div>

      <!--  文字  -->
      <div v-if="type == 1" class="content">
        <div style="display: block" class="first-step">
          <div class="form-item block nextBlock" style="margin-top: 12px;">
            <div class="form-label require" style="margin-bottom: 11px;position: relative;
    display: inline-block;">内容
            </div>
            <div class="form-content">
              <div class="url-input van-cell van-field">
                <div class="van-cell__value van-cell__value--alone van-field__value">
                  <div class="van-field__body" style="height: 100%;">
                <textarea v-model="textData" maxlength="200" rows="2" placeholder="请输入" class="van-field__control"
                          style="height: 100%;">
                </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  图片  -->
      <div v-if="type == 2" class="content">
        <div style="display: block" class="first-step">
          <div class="form-item block nextBlock" style="margin-top: 12px;">
            <div class="form-label require" style="margin-bottom: 11px;position: relative;
    display: inline-block;">图片
            </div>
            <div @click="get" class="form-content">
              <upload @change="getFile" ref="upload"/>
            </div>
          </div>
        </div>
      </div>
      <!--  图文  -->
      <div v-if="type == 3" class="content">
        <div style="display: block" class="first-step">
          <van-cell required style="padding: 16px;font-size: 14px !important;margin-top: 12px" title="链接标题">
            <van-field
                v-model="imageText.title"
                placeholder="请输入标题"
                input-align="right"
            />
          </van-cell>
          <van-cell required style="padding: 16px;font-size: 14px !important;margin-top: 12px" title="链接描述">
            <van-field
                v-model="imageText.description"
                placeholder="请输入链接描述"
                input-align="right"
            />
          </van-cell>
          <van-cell required style="padding: 16px;font-size: 14px !important;margin-top: 12px" title="链接地址">
            <van-field
                v-model="imageText.imageLink"
                placeholder="请输入链接地址"
                input-align="right"
            />
          </van-cell>
          <div class="form-item block nextBlock" style="margin-top: 12px;">
            <div class="form-label require" style="margin-bottom: 11px;position: relative;
    display: inline-block;">链接封面
            </div>
            <div @click="get" class="form-content">
              <upload @change="getFile" ref="upload"/>
            </div>
          </div>
        </div>
      </div>
      <!--  音频  -->
      <div v-if="type == 4" class="content">
        <div style="display: block" class="first-step">
          <div class="form-item block nextBlock" style="margin-top: 12px;">
            <div class="form-label require" style="margin-bottom: 11px;position: relative;
    display: inline-block;">音频
            </div>
            <div @click="get" class="form-content">
              <upload @change="getFile" ref="upload"/>
            </div>
          </div>
        </div>
      </div>
      <!--  视频  -->
      <div v-if="type == 5" class="content">
        <div style="display: block" class="first-step">
          <div class="form-item block nextBlock" style="margin-top: 12px;">
            <div class="form-label require" style="margin-bottom: 11px;position: relative;
    display: inline-block;">视频
            </div>
            <div @click="get" class="form-content">
              <upload @change="getFile" ref="upload"/>
            </div>
          </div>
        </div>
      </div>
      <!--  小程序  -->
      <div v-if="type == 6" class="content">
        <div style="display: block" class="first-step">
          <van-cell required style="padding: 16px;font-size: 14px !important;margin-top: 12px" title="小程序标题">
            <van-field
                v-model="applet.title"
                placeholder="请输入小程序标题"
                input-align="right"
            />
          </van-cell>
          <van-cell required style="padding: 16px;font-size: 14px !important;margin-top: 12px" title="小程序appID">
            <van-field
                v-model="applet.appId"
                placeholder="请输入小程序appID"
                input-align="right"
            />
          </van-cell>
          <van-cell required style="padding: 16px;font-size: 14px !important;margin-top: 12px" title="小程序路径">
            <van-field
                v-model="applet.path"
                placeholder="请输入小程序路径"
                input-align="right"
            />
          </van-cell>
          <div class="form-item block nextBlock" style="margin-top: 12px;">
            <div class="form-label require" style="margin-bottom: 11px;position: relative;
    display: inline-block;">小程序封面
            </div>
            <div @click="get" class="form-content">
              <upload @change="getFile" ref="upload"/>
            </div>
          </div>
        </div>
      </div>
      <!--  文件  -->
      <div v-if="type == 7" class="content">
        <div style="display: block" class="first-step">
          <div class="form-item block nextBlock" style="margin-top: 12px;">
            <div class="form-label require" style="margin-bottom: 11px;position: relative;
    display: inline-block;">文件
            </div>
            <div @click="get" class="form-content">
              <upload @change="getFile" ref="upload"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-button @click="establish" block color="#2b60dd" style="padding: 0 20px;margin-top: 20px;border-radius: 7px;    width: 90%;
    margin: 0 auto;">
      下一步
    </van-button>

    <!--  分组  -->
    <group ref="group" @change="(e)=>group = e"/>
  </div>
</template>

<script>
import upload from "./components/upload";
import group from "./components/group";
import {medium, mediumShow, mediumUpdate} from "../../api/medium";

export default {
  components: {
    upload,
    group
  },
  data() {
    return {
      id: "",
      value: "",
      // 类型
      type: null,
      //  分组
      group: {
        name: '全部分组',
        id: 0
      },
      //  标题
      title: '',
      //  文字
      textData: "",
      //  文件
      file: {},
      //  链接
      imageText: {
        title: '',
        description: '',
        imageLink: '',
        TextTitle: ''
      },
      //  小程序
      applet: {
        title: '',
        appId: '',
        path: ''
      }
    }
  },
  watch: {
    group(i, v) {
      if (!i.name) {
        this.group = {
          name: '全部分组',
          id: 0
        }
      }
    }
  },
  methods: {
    details() {
      const that = this
      mediumShow({
        id: this.id || 0
      }).then(res => {
        const data = res.data
        console.log(data);
        this.title = data.content.title
        this.type = data.type
        console.log("打印加载详情id"+this.id)
        if (data.mediumGroupId == 0) {
          this.group = {
            name: '全部分组',
            id: 0
          }
        } else {
          this.group = {
            name: data.groupName,
            id: data.mediumGroupId
          }
        }

        switch (this.type) {
          case 1:
            this.textData = data.content.content
            break;
          case 2:
            // this
            var file = {
              url: data.content.imageFullPath,
              path: data.content.imagePath,
              name: data.content.imageName
            }
            // this.$refs.upload.get(this.type)
            this.file = file
            setTimeout(() => {
              that.$refs.upload.setFile(file)
            }, 10)
            break;
          case 3:
            // this
            var file = {
              url: data.content.imageFullPath,
              path: data.content.imagePath,
              name: data.content.imageName
            }
            this.imageText = {
              title: data.content.TextTitle,
              description: data.content.description,
              imageLink: data.content.imageLink
            }
            // this.$refs.upload.get(this.type)
            this.file = file
            setTimeout(() => {
              that.$refs.upload.setFile(file)
            }, 10)
            break;
          case 4:
            // this
            var file = {
              url: data.content.voiceFullPath,
              path: data.content.voicePath,
              name: data.content.voiceName
            }
            // this.$refs.upload.get(this.type)
            this.file = file
            setTimeout(() => {
              that.$refs.upload.setFile(file)
            }, 10)
            break;
          case 5:
            // this
            var file = {
              url: data.content.videoFullPath,
              path: data.content.videoPath,
              name: data.content.videoName
            }
            // this.$refs.upload.get(this.type)
            this.file = file
            setTimeout(() => {
              that.$refs.upload.setFile(file)
            }, 10)
            break;
          case 6:
            // this
            var file = {
              url: data.content.imageFullPath,
              path: data.content.imagePath,
              name: data.content.imageName
            }
            // this.$refs.upload.get(this.type)
            this.file = file
              this.applet = {
                path: data.content.page,
                title: data.content.titleApplet,
                appId: data.content.appid
              }
            setTimeout(() => {
              that.$refs.upload.setFile(file)
            }, 10)
            break;
          case 7:
            // this
            var file = {
              url: data.content.fileFullPath,
              path: data.content.filePath,
              name: data.content.fileName
            }
            // this.$refs.upload.get(this.type)
            this.file = file
            setTimeout(() => {
              that.$refs.upload.setFile(file)
            }, 10)
            break;
        }
      })
    },
    onClickLeft() {
      this.$router.back()
    },
    clearGroup() {
      this.group = {
        name: '全部分组',
        id: 0
      }
    },
    get() {
      this.$refs.upload.get(this.type)
    },
    getFile(e) {
      console.log("打印上传日志文件"+e)
      this.file = e
    },
    establish() {
      let data = {
        mediumGroupId: this.group.id,
        type: this.type,
        content: {
          title: this.title,
        }
      }
      switch (Number(this.type)) {
        case 1:
          data.content.content = this.textData
          break
        case 2:
          data.content.fileTitle = this.title
          data.content.imageName = this.file.name
          data.content.imagePath = this.file.path
          break
        case 3:
          data.content.TextTitle = this.imageText.title
          data.content.description = this.imageText.description
            data.content.imageLink = this.imageText.imageLink
          data.content.imagePath = this.file.path
            data.content.imageName = this.file.name
          break
        case 4:
          data.content.fileTitle = this.title
          data.content.voiceName = this.file.name
          data.content.voicePath = this.file.path
          break
        case 5:
          data.content.fileTitle = this.title
          data.content.videoName = this.file.name
          data.content.videoPath = this.file.path
          break
        case 6:
          data.content.titleApplet = this.applet.title
          data.content.page = this.applet.path
          data.content.imagePath = this.file.path
          data.content.imageName = this.file.name
          data.content.appid = this.applet.appId
          break
        case 7:
          data.content.fileTitle = this.title
          data.content.fileName = this.file.name
          data.content.filePath = this.file.path
          break
      }
      if(!this.title){
        this.$message.error('请输入标题')
        return
      }

      for (let key in data.content) {
        if(!data.content[key]){
          this.$message.error('请完善必填内容')
          return
        }
      }
      console.log("打印提交数据"+data)
      if(!this.id) {
        medium(data).then(res => {
          this.$message.success('创建成功')
          this.$router.back()
        })
      }else{
        data.id = Number(this.id)
        mediumUpdate(data).then(res => {
          this.$message.success('修改成功')
          this.$router.back()
        })
      }

    }
  },
  created() {
    if (this.$route.query.type || this.$route.query.id) {
      if (this.$route.query.type) {
        this.type = this.$route.query.type
      } else {
        this.id = this.$route.query.id
        this.details()
      }
    } else {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-cell {
  padding: 0;
}

/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-dropdown-menu__bar {
  height: 36px;
}

/deep/ .van-ellipsis {
  font-size: 13px;
}

/deep/ .van-cell__title {
  span {
    font-size: 14px;
  }
}

/deep/ .van-cell-group, .van-cell {
  border-radius: 7px;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #f2f4f8;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    background: #f2f4f8;

    padding: 0 12px;
    padding-top: 12px;
    //padding-bottom: 125px;
    //padding-top: 5px;
    .url-input {
      font-size: 16px;
      color: #ccc;
      min-height: 151px;
      border-radius: 4px;
      background-color: #f8f9fb;
      padding: 13px 16px;
      line-height: 20px;
    }

    img {
      width: 22px;
      height: 22px;
    }

    /deep/ .van-cell::after {
      position: relative;
    }

    .tips {
      font-size: 12px;
      color: #999;
      line-height: 17px;
      padding-left: 12px;
      margin-top: 12px;
    }

    .upload {
      width: 75px;
      height: 75px;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #e7ebf0;
      color: #1890ff;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fixed {
      position: fixed;
      bottom: 0;
      height: 72px;
      width: 100%;
      background: #fff;
      left: 0;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      div:nth-child(1) {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-left: 13px;
      }

      div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: .9;
        margin-right: 17px;
      }
    }


    .require {
      &::after {
        content: "*";
        position: absolute;
        top: 2px;
        left: -12.5px;
        color: #e76d74;
        font-size: 16px;
        display: inline-block;
      }
    }

    .form-item {
      padding: 16px;
      display: block;
      background-color: #fff;
      border-radius: 7px;

      .form-label {
        font-size: 14px;
        color: #545454;
        line-height: 24px;
        margin-bottom: 4px;
        margin-left: 10px;
      }

      .form-content {
        font-size: 16px;
        color: #999;
        line-height: 26px;
      }
    }


  }
}
</style>
